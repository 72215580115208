import { getPxScriptContent } from 'utils/human'
import '../assets/style/style.scss'
import ProgressBar from '@components/ui/progress-bar'
import Head from 'next/head'
import App from 'next/app'
import { loginCheck } from 'utils/general'
import { GlobalContextProvider } from '../contexts/globalcontextt'

const PxScript = () => {
  if (!process.env.NEXT_PUBLIC_HUMAN_CODE) return null

  const pxCode = process.env.NEXT_PUBLIC_HUMAN_CODE
  const scriptContent = getPxScriptContent(pxCode)

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: scriptContent }} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.${pxCode}_asyncInit = function (px) {
            px.Events.on('risk', function (risk, name) {
              localStorage.setItem("pxcookie", \`\${name}=\${risk}\`);
            });
          };
        `,
        }}
      />
    </Head>
  )
}

function MyApp({ Component, pageProps }) {
  return (
    <GlobalContextProvider pageProps={pageProps}>
      <ProgressBar />
      <PxScript />
      <Component {...pageProps} />
    </GlobalContextProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)

  let isLogin = loginCheck(appContext.ctx)

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      isLogin,
    },
  }
}

export default MyApp
